import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import LandingPage from '../components/homepage/LandingPage';
import NavBar from '../components/NavBar';
import AboutBlurb from '../components/homepage/AboutBlurb';
import SelectedWork from '../components/homepage/SelectedWork';
// import SelectedPlay from '../components/homepage/SelectedPlay';
import Contact from '../components/Contact';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
  },
  stripes: {
    textAlign: 'center',
    height: '30vh',
    opacity: '25%',
    backgroundImage:
      'repeating-linear-gradient(45deg, #336666 0, #336666 3px, transparent 3px, transparent 30px)',
  },
  stripesRight: {
    padding: 0,
    margin: 0,
    bottom: 25,
    height: '20vh',
    opacity: '25%',
    backgroundImage:
      'repeating-linear-gradient(45deg, #336666 0, #336666 3px, transparent 3px, transparent 30px)',
    // transform: 'skewY(-3deg)',
    zIndex: -10,
  },
  font: {
    fontFamily: 'Roboto Condensed',
    textTransform: 'uppercase',
    fontWeight: 500,
    textAlign: 'center',
  },
  center: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default function Index() {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="xl">
        <Box my={5}>
          <NavBar />
        </Box>
        <LandingPage />
        <AboutBlurb />
        <Box my={7}>
          <Box position="relative" p={0} maxWidth="100vw" maxHeight="75vh">
            <Box position="absolute" className={classes.center}>
              <Typography variant="h2" className={classes.font}>
                Selected Work
              </Typography>
            </Box>
            <Box m={0} p={0} maxWidth="100vw" className={classes.stripes}></Box>
          </Box>
          <Box mb={7}>
            <SelectedWork />
          </Box>
        </Box>
        {/* COMMENTED OUT UNTIL FUN PROJECTS ARE IN THE WORKS
        <Box
          display="flex"
          className={classes.titleContainer}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h3">For Fun</Typography>
        </Box>
        <Box className={classes.stripes}></Box>
        <SelectedPlay /> */}
        <Box className={classes.stripesRight}></Box>
      </Container>
      <Box mt={5}>
        <Contact />
      </Box>
    </>
  );
}
