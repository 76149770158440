import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '../Link';

import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PDF from '../../documents/Resume.pdf';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
  },
  paper: {
    background: theme.palette.primary.dark,
    color: theme.palette.background.default,
    borderRadius: 10,
    marginBottom: theme.spacing(3),
    backgroundImage:
      'repeating-linear-gradient(135deg, #192525 0, #192525 1px, transparent 1px, transparent 30px)',
  },
  arrowContainer: {
    textAlign: 'center',
    position: 'relative',
    bottom: 40,
  },
  icon: {
    color: theme.palette.background.default,
    textAlign: 'center',
  },
  photoContainer: {
    display: 'block',
    position: 'relative',
    bottom: 0,
    left: 0,
    right: 0,
  },
  aboutMe: {
    textAlign: 'left',
  },
  margin: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  button: {
    color: theme.palette.background.default,
    border: '1px solid rgba(191, 86, 61)',
  },
  link: {
    color: theme.palette.background.default,
    textDecoration: 'none',
  },
}));

export default function AboutBlurb() {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query Me {
      image: file(relativePath: { eq: "me.png" }) {
        id
        childImageSharp {
          fixed(width: 600) {
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <Box className={classes.paper}>
      <Grid
        container
        xl
        alignItems="center"
        justify="center"
        wrap="wrap"
        spacing={2}
      >
        <Grid item xs={12} className={classes.arrowContainer}>
          <FontAwesomeIcon
            icon={faCaretDown}
            className={classes.icon}
            size="5x"
          ></FontAwesomeIcon>
        </Grid>
        <Grid item xs={12} md={6} className={classes.photoContainer}>
          <Img
            alt="jenn misewicz photo"
            fluid={data.image.childImageSharp.fluid}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.aboutMe}>
          <Box pl={3} pt={1} pr={3} pb={1}>
            <Typography variant="h2">Hey, I'm Jenn!</Typography>
          </Box>
          <Box pl={3} pt={1} pr={3} pb={7}>
            <Typography variant="h4">
              <Box pt={1}>
                I'm passionate about designing experiences that live on the
                internet, in real life, & somewhere inbetween.
              </Box>
              <Box pt={3}>
                Here you'll find a collection of recent work & little
                experiments I've dreamt up along the way.
              </Box>
            </Typography>
            <Link to="/about" className={classes.link}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                className={classes.margin}
              >
                TELL ME MORE
              </Button>
            </Link>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              className={classes.button}
            >
              <a
                href={PDF}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                RESUME
              </a>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
