import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import {
  faLinkedin,
  faGithub,
  faBehance,
} from '@fortawesome/free-brands-svg-icons';

import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  name: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
    fontSize: '2rem',
    fontWeight: 500,
  },
  title: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: '1.41rem',
  },
  icon: {
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
    margin: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: '1.4rem',
  },
}));

export default function LandingPage() {
  const classes = useStyles();
  return (
    <Container maxWidth="md" className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        height="75vh"
      >
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
        >
          <Box className={classes.name}>jenn misewicz</Box>
          <Box className={classes.title}>
            &#123; developer + designer &#125;
          </Box>
        </Box>
        <Box className={classes.icon}>
          <Box component="span" className={classes.icon}>
            <Link
              href="mailto:jennmez@gmail.com"
              target="_blank"
              rel="noreferrer"
              color="primary"
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                className="icon"
              ></FontAwesomeIcon>
            </Link>
          </Box>
          <Box component="span" className={classes.icon}>
            <Link
              href="https://www.linkedin.com/in/jennmez"
              target="_blank"
              rel="noreferrer"
              color="primary"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                className="icon"
              ></FontAwesomeIcon>
            </Link>
          </Box>
          <Box component="span" className={classes.icon}>
            <Link
              href="https://www.github.com/jennmez"
              color="primary"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faGithub}
                className="icon"
              ></FontAwesomeIcon>
            </Link>
          </Box>
          <Box component="span" className={classes.icon}>
            <Link
              href="https://www.behance.net/jennmez"
              target="_blank"
              rel="noreferrer"
              color="primary"
            >
              <FontAwesomeIcon
                icon={faBehance}
                className="icon"
              ></FontAwesomeIcon>
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
